<template>
  <div class="cart-item">
    <v-list-item
      :to="
        product.existsInShop
          ? { name: 'Product', params: { slug: product.slug } }
          : null
      "
      :class="
        $vuetify.breakpoint.xs ? 'py-3 is-checkout' : 'py-2 is-not-checkout'
      "
    >
      <v-row class="w-100 my-2 my-sm-0" align="center" justify="space-between">
        <v-col cols="12" sm="6" class="pa-0">
          <div class="d-flex">
            <v-list-item-avatar width="70" height="70" rouned="0" class="my-0">
              <img
                v-if="product.existsInShop"
                :src="product.mediaURL"
                :alt="product.name"
                onerror="this.onerror=null;this.src='/no-icon.png'"
              />
            </v-list-item-avatar>

            <v-list-item-content class="default--text pa-0 pa-sm-2">
              <div class="font-weight-bold text-body-2">
                {{ product.wp_amount }} {{ product.wp_unit }}
                {{ product.wp_ingredient }}
              </div>
            </v-list-item-content>
          </div>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <v-list-item-action
            class="d-flex flex-row align-center justify-end mr-0 w-100 mt-3 mt-sm-0 mb-0"
          >
            <template v-if="product.available > 0">
              <ProductPrice :product="product" :isList="true"></ProductPrice>
              <div
                class="qty-wrap"
                :class="{ 'not-empty': quantity > 0 }"
                transition="fab-transition"
              >
                <a
                  role="button"
                  class="minus"
                  v-ripple
                  @click.stop.prevent="minus"
                  @mousedown.stop
                  data-test="sub-qty-btn"
                >
                  <!-- v-ripple -->
                  <v-icon>$minus</v-icon>
                </a>
                <div class="val-cnt">
                  <span class="val" data-test="cart-item-product-val"
                    >{{ quantity }} {{ unit }}</span
                  >
                  <span class="small">{{ quantityPerUnit }}</span>
                </div>
                <a
                  role="button"
                  class="plus"
                  v-ripple
                  @click.stop.prevent="plus"
                  @mousedown.stop
                  data-test="add-to-cart"
                >
                  <!-- v-ripple -->
                  <v-icon>$plus</v-icon>
                </a>
              </div>
              <v-btn
                elevation="0"
                class="mx-2 add-to-cart-btn"
                outlined
                color="primary"
                aria-label="Aggiungi prodotto al carrello"
                @click.stop.prevent="addToCart(quantity)"
              >
                <v-icon>$cart</v-icon></v-btn
              >
            </template>

            <div v-else class="not-available">
              Il prodotto non è disponibile
            </div>

            <v-tooltip top :key="heartKey">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click.stop.prevent="addToFavorites"
                  @mousedown.stop
                  aria-label="Aggiungi ad una lista"
                >
                  <v-icon color="primary" v-if="highlight">$starFull</v-icon>
                  <v-icon v-else color="grey darken-1">$star</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("product.addToList") }}</span>
            </v-tooltip>

            <!-- <div
              v-if="product.available > 0"
              class="d-flex align-center w-100"
              :class="
                $vuetify.breakpoint.xs ? 'justify-space-between' : 'justify-end'
              "
            >
              <ProductQty :product="product" />
            </div> -->
          </v-list-item-action>
        </v-col>
      </v-row>
    </v-list-item>
  </div>
</template>
<style lang="scss" scoped>
.product-price {
  padding-right: 16px;
}
.qty-wrap {
  width: 100%;
  min-width: 130px;
  max-width: 200px;
}

.product-price {
  text-align: center;
  @media (max-width: 320px) {
    min-width: auto;
  }
}
.header {
  height: 45px;
  width: 100%;
  .icons-box {
    display: flex;
    margin-left: 3px;
  }
}
.not-available {
  color: var(--v-error-base);
  font-size: 12px;
  white-space: normal;
  height: 40px;
  display: flex;
  align-items: flex-end;
  text-align: right;
}
.product-card {
  width: 100%;
}
.ingredient-container,
.ingredient-col {
  max-height: 50px;
  margin-bottom: 16px !important;
}
.name {
  font-size: 1rem !important;
}
.cur-price {
  font-size: 18px !important;
}
.product-img {
  max-width: 64px;
}
.actions {
  justify-content: flex-end !important;
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .ingredient-container {
    height: auto;
    margin-bottom: 16px !important;
  }
  .product-img {
    max-width: 42px;
  }
  .name {
    font-size: 11px !important;
  }
  .qty-wrap div {
    font-size: 11px !important;
    padding: 0 4px !important;
  }
  .qty-wrap i {
    font-size: 18px !important;
  }
}
</style>
<script>
//TODO sistemare la visualizzazione della card su iphone SE dimensione della description
// import ProductAcqLimit from "./ProductAcqLimit";
// import ProductPromo from "./ProductPromo";
// import ProductPrice from "./ProductPrice";
import { mapState, mapGetters } from "vuex";
import productMixin from "~/mixins/product";
import ProductPrice from "./ProductPrice.vue";

export default {
  name: "ProductCard",
  props: ["product"],
  // components: { ProductClass, ProductAcqLimit, ProductPromo, ProductPrice },
  mixins: [productMixin],
  components: { ProductPrice },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId",
      isAuthenticatedss: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    productClasses() {
      if (global.config.filterProductClasses && this.product.productClasses) {
        return this.product.productClasses.filter(
          p =>
            global.config.filterProductClasses.includes(p.productClassId) &&
            p.productClassTypeId == 2 &&
            p.productClassGroupId != 1
        );
      } else {
        return (
          this.product?.productClasses &&
          this.product?.productClasses.filter(
            p => p.productClassTypeId == 2 && p.productClassGroupId != 1
          )
        );
      }
    },
    item() {
      return null;
    }
  }
};
</script>
